import { fromBase64, toBase64 } from '@change/core/base64';

export function fromBase64Url(input: string): string {
	const remainder = input.length % 4;
	const recodedString = input
		.replace(/-/g, '+') // Convert '-' to '+'
		.replace(/_/g, '/') // Convert '_' to '/'
		.padEnd(input.length + (remainder === 0 ? 0 : 4 - remainder), '=');

	return fromBase64(recodedString);
}

export function toBase64Url(input: string): string {
	return toBase64(input).replace(/[+/=]/g, (match) => {
		switch (match) {
			case '+':
				return '-'; // Convert '+' to '-'
			case '/':
				return '_'; // Convert '/' to '_'
			case '=':
				return ''; // Remove ending '='
			default:
				return match;
		}
	});
}
