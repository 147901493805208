import { z } from 'zod';

import { stripHtmlTags } from '@change/core/html';

export const aiInputSchema = z.object({
	mainPrompt: z.string(),
	personalStory: z.string().optional(),
	location: z.string().default(''),
});

export type AiInput = z.infer<typeof aiInputSchema>;

export const generatedDescriptionSchema = z.object({
	result: z.object({
		petitionTitle: z.string().transform((title) => stripHtmlTags(title)),
		petitionDetails: z.string().transform((details) => stripHtmlTags(details).replaceAll('\n', '<br>')),
	}),
});

export type GeneratedDescription = z.infer<typeof generatedDescriptionSchema>;
