import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCookiePrefAsync, useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

type Visited = Readonly<{
	visited: number;
}>;

type Result = ModelHookResult<
	undefined,
	{
		setMembershipReminderCookie: ({ visited }: Visited) => void;
		removeMembershipReminderCookie: () => void;
	}
>;

export function useMembershipReminderCookie(): Result {
	const { cookies } = useUtilityContext();
	const session = useSessionAsync();
	const cookieAllowedState = useCookiePrefAsync('preferences');
	const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;

	const setMembershipReminderCookie = useCallback(
		({ visited }: Visited) => {
			if (isLoaded(session) && session.value.loginState !== 'GUEST' && cookieAllowed) {
				// do not set cookie for guests
				const expiryDate = new Date();
				expiryDate.setTime(expiryDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
				cookies.set('app_meta_membership_reminder', JSON.stringify({ visited }), {
					expires: expiryDate,
				});
			}
		},
		[cookieAllowed, cookies, session],
	);

	const removeMembershipReminderCookie = useCallback(() => {
		if (cookies.get('app_meta_membership_reminder')) {
			cookies.remove('app_meta_membership_reminder');
		}
	}, [cookies]);

	return {
		actions: {
			setMembershipReminderCookie,
			removeMembershipReminderCookie,
		},
	};
}
