// # TODO: Move countrySettings to @change/config/countries
import { countrySettings } from '@change/config/legacy/countries';
import type { UtilityContext } from '@change/core/react/utilityContext';
import type { SessionUser } from '@change/core/session';

import { RedirectError } from 'src/shared/error';

import {
	getContactDmConfig,
	getSupporterVoices,
	getSupporterVoicesConfig,
} from 'src/app/pages/petition/details/shared/api';
import type { PetitionPageVideosQuery, SupporterVoicesConfig } from 'src/app/pages/petition/details/shared/api';
import { getSubscriptionConfig } from 'src/app/shared/api/payments';
import { differenceInDays } from 'src/app/shared/utils/dates';

import type { PetitionDetailsDataQuery, PetitionDetailsDataQueryVariables } from './api.graphql';
import { PetitionDetailsData } from './api.graphql';
import { getPetitionStrengthScore } from './getPetitionStrengthScore';

export type SupporterVoicesData = {
	supporterVoicesEnabled: boolean;
	videos?: PetitionPageVideosQuery | null | undefined;
	openVideoCreationFromPetitionDetails?: boolean | undefined;
};

type SubscriptionConfig =
	| Pick<NonNullable<Awaited<ReturnType<typeof getSubscriptionConfig>>>, 'amounts' | 'defaultAmount'>
	| undefined;

export type PetitionDetailsPageData = {
	petition: NonNullable<PetitionDetailsDataQuery['petition']>;
	recommendedDecisionMakers: NonNullable<PetitionDetailsDataQuery['recommendedDecisionMakers']>['items'];
	createdLessThanOneDayAgo: boolean;
	score: number;
	supporterVoicesData: SupporterVoicesData;
	emailDm: { emailSubjectLine: string; emailBody: readonly string[] };
	currency: string;
	subscriptionConfig: SubscriptionConfig;
};

// eslint-disable-next-line max-lines-per-function
export async function getPetitionDetailsPageData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{
		locale,
		countryCode,
		loadBandits,
		supporterVoicesConfig,
		sessionUUID,
		sessionUser,
	}: {
		locale: string;
		countryCode: string;
		loadBandits: boolean;
		supporterVoicesConfig?: SupporterVoicesConfig;
		sessionUser?: SessionUser | null;
		sessionUUID?: string;
	},
): Promise<PetitionDetailsPageData> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { dmContactsToFetch, emailBody, emailSubjectLine } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<PetitionDetailsDataQuery, PetitionDetailsDataQueryVariables>({
		query: PetitionDetailsData,
		variables: { slugOrId, locale, countryCode, loadBandits, dmContactsToFetch },
		rejectOnError: true,
		// to separate query from other batched queries
		important: true,
	});

	const config =
		supporterVoicesConfig || (await getSupporterVoicesConfig(utilityContext, slugOrId, { sessionUUID, sessionUser }));

	const supporterVoicesData = await getSupporterVoices(
		utilityContext,
		slugOrId,
		config,
		data?.petition?.videoSettingsMetadata.disableDisplay,
	);

	const currency = countrySettings[countryCode]?.currency || 'USD';

	const subscriptionConfig = await getSubscriptionConfig(utilityContext, currency);

	if (!data?.petition) {
		throw new RedirectError({ url: '/?petition_not_found=true' });
	}

	return {
		petition: data.petition,
		recommendedDecisionMakers: data.recommendedDecisionMakers?.items || [],
		createdLessThanOneDayAgo: differenceInDays(new Date(), new Date(data.petition.createdAt)) < 1,
		score: getPetitionStrengthScore(data.petition),
		supporterVoicesData,
		emailDm: { emailSubjectLine, emailBody },
		currency,
		subscriptionConfig: subscriptionConfig
			? { amounts: subscriptionConfig.amounts, defaultAmount: subscriptionConfig.defaultAmount }
			: undefined,
	};
}
