import type { TrackingFn } from '@change/core/react/tracking';
import { getWindow } from '@change/core/window';

import { trackShareFailure, trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
		failure: string;
	};
};

export async function shareNative(
	url: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): Promise<boolean> {
	const { navigator } = getWindow();
	try {
		await navigator.share({ url });
		trackShareSuccess(trackingEvents.success, trackingData, track);
		return true;
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if ((e as Error).name === 'AbortError') {
			return false;
		}
		trackShareFailure(trackingEvents.failure, trackingData, track);
		return false;
	}
}
