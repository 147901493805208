import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		experimentName: { type: 'string', optional: true },
		mobileExperimentName: { type: 'string', optional: true },
		desktopExperimentName: { type: 'string', optional: true },
	},
});

export const FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT = createFcmConfig('new_petition_page_access_experiment', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		experimentName: undefined,
		mobileExperimentName: undefined,
		desktopExperimentName: undefined,
	} satisfies ReturnType<typeof normalizer>,
});
