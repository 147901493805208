import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTION_PAYMENT_WALLET_EXPERIMENT = createFcmConfig('promotion_payment_wallet_experiment', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
			},
		}),
	),
	defaultValue: undefined,
});
