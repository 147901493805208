import { ZodError } from 'zod';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import { UNKNOWN_ERROR, VALIDATION_ERROR } from './constants';
import { aiInputSchema, generatedDescriptionSchema } from './schema';

type MainPrompt = NonNullable<PetitionInput['aiInput']>['mainPrompt'];
type PersonalStory = NonNullable<PetitionInput['aiInput']>['personalStory'];
type Input = { mainPrompt: MainPrompt; personalStory?: PersonalStory; location?: string };

type GenerateAiDescriptionReturn = { ask: string; description: string };
export async function generateAiDescription(
	input: Input,
	{ http: { post }, errorReporter, tracker }: UtilityContext,
): Promise<GenerateAiDescriptionReturn> {
	try {
		const parsedInput = aiInputSchema.parse(input);

		void tracker.track('future_sap_ai_generate');
		const result = await post('https://us-central1-niftic-agency.cloudfunctions.net/openai/generate-draft', {
			petitionTitle: parsedInput.mainPrompt,
			geo: parsedInput.location,
			personal: parsedInput.personalStory,
			urgent: '',
			newVersion: true,
		});

		const parsedResult = generatedDescriptionSchema.parse(result);

		void tracker.track('future_sap_ai_generate_loaded');
		return { ask: parsedResult.result.petitionTitle, description: parsedResult.result.petitionDetails };
	} catch (err: unknown) {
		void tracker.track('future_sap_ai_generate_error');
		const params = { page: 'start-a-petition', event: 'generate-ai-description' };

		if (err instanceof ZodError) {
			const error = new Error('SAP: Failed to generate AI description (validation error)', { cause: err });
			void errorReporter.report({ error, params });
			throw new Error(VALIDATION_ERROR, { cause: err });
		}

		const error = new Error('SAP: Failed to generate AI description', { cause: err });
		void errorReporter.report({ error, params });
		const unknownError = err instanceof Error ? err : new Error(UNKNOWN_ERROR, { cause: err });
		throw unknownError;
	}
}
