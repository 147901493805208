import { withPrefetchedData } from '@change/core/react/prefetch';

import type { MembershipReminderPopupPrefetchedDataProps } from './context';
import { MembershipReminderPopupContextProvider, prefetchContext } from './context';
import { MembershipReminderPopup as Layout } from './layout';

type MembershipReminderPopupProps = MembershipReminderPopupPrefetchedDataProps;

export const MembershipReminderPopup = withPrefetchedData(
	function MembershipReminderPopupEntry({ prefetchedData }: MembershipReminderPopupProps): React.JSX.Element {
		return (
			<MembershipReminderPopupContextProvider prefetchedData={prefetchedData}>
				<Layout />
			</MembershipReminderPopupContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => prefetchContext(context),
	},
);
