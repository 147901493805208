import { z } from 'zod';

// the only required fields for publishing are `ask` and `description`
const askSchema = z.string().min(1);
const descriptionSchema = z.string().min(1);

// the rest are optional fields
const geoReachSchema = z.enum(['global', 'national', 'local']);
const aiPromptChoiceSchema = z.enum(['scratch', 'ai']);
const aiInputSchema = z.object({ mainPrompt: z.string().min(1), personalStory: z.string().min(1).optional() });
const aiResultSchema = z
	.object({ originalAsk: z.string(), originalDescription: z.string() })
	.extend({ ask: askSchema, description: descriptionSchema });
const locationSchema = z.object({ localizedName: z.string().min(1), googlePlacesId: z.string().min(1) });
const mediaSchema = z.object({ url: z.string().min(1) });

const inputSchema = z.object({
	ask: askSchema.optional(),
	description: descriptionSchema.optional(),
	geoReach: geoReachSchema.optional(),
	aiPromptChoice: aiPromptChoiceSchema.nullable().optional(),
	aiInput: aiInputSchema.nullable().optional(),
	aiResult: aiResultSchema.nullable().optional(),
	location: locationSchema.nullable().optional(),
	media: mediaSchema.nullable().optional(),
});

const outputSchema = z.object({
	ask: askSchema,
	description: descriptionSchema,
	aiAsk: z.string().nullable().optional(),
	aiDescription: z.string().nullable().optional(),
	geoReach: geoReachSchema.nullable().optional(),
	location: locationSchema.nullable().optional(),
	media: z
		.object({ photo: z.object({ srcUrl: z.string().min(1) }) })
		.nullable()
		.optional(),
});

const transformBaseSchema = (input: z.infer<typeof inputSchema>) => {
	// pull off the fields that aren't needed in the output
	const { aiPromptChoice, aiInput, aiResult, ...rest } = input;

	if (aiPromptChoice === 'ai' && aiResult) {
		return {
			...rest,
			ask: aiResult.ask,
			description: aiResult.description,
			aiAsk: aiResult.originalAsk,
			aiDescription: aiResult.originalDescription,
		};
	}

	return rest;
};

const transformMediaSchema = ({ media, ...rest }: z.infer<typeof inputSchema>) => {
	if (!media) return rest;

	return {
		...rest,
		media: { photo: { srcUrl: media.url } },
	};
};

export const schema = inputSchema.transform(transformBaseSchema).transform(transformMediaSchema).pipe(outputSchema);
export type Schema = z.infer<typeof schema>;
