import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

type ShareChannel =
	| 'copy'
	| 'mobileNativeShare'
	| 'facebook'
	| 'instagram'
	| 'whatsapp'
	| 'twitter'
	| 'email'
	| 'facebookMessenger'
	| 'sms'
	| 'line'
	| 'vk'
	// TODO older names that we should phase out
	| 'copylink'
	| 'facebook_message';
const SHARE_CHANNELS: readonly ShareChannel[] = [
	'copy',
	'mobileNativeShare',
	'facebook',
	'instagram',
	'whatsapp',
	'twitter',
	'email',
	'facebookMessenger',
	'sms',
	'line',
	'vk',
	// TODO older names that we should phase out
	'copylink',
	'facebook_message',
];

export const FCM_PSF_SHARE_MESSAGE_BUTTON_ORDER = createFcmConfig('psf_share_message_button_order', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'enum',
				values: SHARE_CHANNELS,
			},
		}),
	),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	defaultValue: [
		'copy',
		'mobileNativeShare',
		'whatsapp',
		'email',
		'facebookMessenger',
		'twitter',
		'sms',
	] as readonly ShareChannel[],
});
