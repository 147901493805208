import { useCallback } from 'react';

import { MissingReplacementsError, replace } from '@change/core/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { Sharer } from 'src/app/pages/petitionGamma/details/shared/types';

import { useHeadlineReplacements } from './headlineReplacements';

export function useGetBanditHeadlineText(sharer: Sharer | undefined): (text: string | undefined) => string | undefined {
	const headlineReplacements = useHeadlineReplacements(sharer);

	const {
		errorReporter: { report },
	} = useUtilityContext();

	return useCallback(
		(text: string | undefined) => {
			if (!text) return text;
			try {
				return replace(text, headlineReplacements);
			} catch (e) {
				if (e instanceof MissingReplacementsError) {
					void report({
						error: new Error('bandit: missing replacement values'),
						params: {
							translation: e.translation,
							missingReplacements: e.missingReplacements,
						},
					});
					return e.finalTranslation;
				}
				return text;
			}
		},
		[headlineReplacements, report],
	);
}

export type { Sharer };
