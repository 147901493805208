import type { UtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import type { GenerateAiDraftMutation, GenerateAiDraftMutationVariables } from './generateAiDraft.graphql';
import { GenerateAiDraft } from './generateAiDraft.graphql';
import { generateAiDescription as legacyGenerateAiDraft } from './legacyApi';

type MainPrompt = NonNullable<NonNullable<PetitionInput['aiInput']>['mainPrompt']>;
type PersonalStory = NonNullable<PetitionInput['aiInput']>['personalStory'];
type Input = { mainPrompt: MainPrompt; personalStory?: PersonalStory; locationName?: string };

type GenerateAiDescriptionReturn = { ask: string; description: string };
export async function generateAiDraft(
	input: Input,
	{ gql: { fetch }, tracker, errorReporter }: UtilityContext,
): Promise<GenerateAiDescriptionReturn> {
	void tracker.track('future_sap_ai_generate');

	try {
		const { data, errors } = await fetch<GenerateAiDraftMutation, GenerateAiDraftMutationVariables>({
			query: GenerateAiDraft,
			variables: { input },
		});

		if (errors) throw Error(JSON.stringify(errors));
		if (!data) throw Error('No data returned from mutation.');
		if (data.generateAiDraft.__typename === 'GenerateAiDraftError') throw Error(data.generateAiDraft.reason);

		void tracker.track('future_sap_ai_generate_loaded');
		return data.generateAiDraft;
	} catch (e: unknown) {
		void tracker.track('future_sap_ai_generate_error');
		const error = new Error('SAP: Failed to generate AI description', { cause: e });
		void errorReporter.report({ error, params: { page: 'start-a-petition', event: 'generate-ai-description' } });
		throw error;
	}
}

// Temporary wrapper while we make sure that GraphQL mutation doesn't break anything
// TODO: Remove this along with legacyAPI.ts and schema.ts
export async function generateAiDraftWrapper(
	input: Input,
	utilityContext: UtilityContext,
	legacyAPI: boolean,
): Promise<GenerateAiDescriptionReturn> {
	if (legacyAPI) return legacyGenerateAiDraft(input, utilityContext);

	return generateAiDraft(input, utilityContext);
}
