import type { UtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import type {
	SapCreatePublishedPetitionMutation,
	SapCreatePublishedPetitionMutationVariables,
} from './createPublishedPetition.graphql';
import { SAPCreatePublishedPetition } from './createPublishedPetition.graphql';
import { SapPetitionSaveError } from './errors';
import { schema } from './schema';

type CreatePublishedPetitionParams = { petition: PetitionInput };
type CreatePublishedPetitionReturn = Extract<
	SapCreatePublishedPetitionMutation['createPublishedPetition'],
	{ __typename: 'CreatePublishedPetitionSuccess' }
>['petition'];
export async function createPublishedPetition(
	{ petition }: CreatePublishedPetitionParams,
	{ gql: { fetch }, errorReporter }: UtilityContext,
): Promise<CreatePublishedPetitionReturn> {
	try {
		const { data: petitionInput, error: validationError } = schema.safeParse(petition);

		// creating a new error with a custom message instead of using ZodError so we can easily search in logs
		if (validationError) throw new SapPetitionSaveError('InvalidInput', { cause: validationError });

		const { data } = await fetch<SapCreatePublishedPetitionMutation, SapCreatePublishedPetitionMutationVariables>({
			query: SAPCreatePublishedPetition,
			variables: {
				input: petitionInput,
			},
			rejectOnError: true,
		});

		if (!data) throw new SapPetitionSaveError('NoResponseData');

		if (data.createPublishedPetition.__typename !== 'CreatePublishedPetitionSuccess') {
			const typename = data.createPublishedPetition.__typename;
			throw new SapPetitionSaveError(typename);
		}

		return data.createPublishedPetition.petition;
	} catch (err) {
		const error = err instanceof SapPetitionSaveError ? err : new SapPetitionSaveError(undefined, { cause: err });
		void errorReporter.report({ error });
		throw error;
	}
}
