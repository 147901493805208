import { Button, FakeLink } from '@change/design-system/components/actions';
import { Flex } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';
import { Heading, Text } from '@change/design-system/typography';

export type ReminderModalOptions = {
	heading: string;
	text: string;
	continueLabel: string;
	remindMeLaterLabel: string;
	continueDataQa: string;
	remindMeLaterDataQa: string;
};

export const useReminderModal = createModalHook<ReminderModalOptions, { choice: boolean }>({
	name: 'ReminderModal',
	heading: function CustomHeadingModalHeading({ options: { heading } }) {
		return (
			<Flex
				sx={{ justifyContent: 'center' }}
				marginX={[0, 16]}
				marginTop={24}
				aria-labelledby="before-you-go-modal-heading"
				aria-describedby="before-you-go-modal-body"
				role="dialog"
			>
				<Heading
					size={['h3', 'h2']}
					p={4}
					mx={[8, 16]}
					py="auto"
					id="before-you-go-modal-heading"
					aria-live="assertive"
					breakWord
					sx={{ textAlign: 'center' }}
				>
					{heading}
				</Heading>
			</Flex>
		);
	},
	body: function ConfirmModalBody({
		options: { text, continueLabel, remindMeLaterLabel, continueDataQa, remindMeLaterDataQa },
		closeModal,
	}) {
		return (
			<Flex sx={{ flexDirection: 'column', textAlign: 'center' }} marginX={[8, 16, 32]}>
				<Text as="p" mt={16} sx={{ lineHeight: '28px' }} id="before-you-go-modal-body" aria-live="polite">
					{text}
				</Text>
				<Flex sx={{ flexDirection: 'column' }} marginX={[8, 16, 32]} marginY={[8, 16]}>
					<Button
						variant="primary"
						data-qa={continueDataQa}
						onClick={() => closeModal({ choice: true })}
						mt={26}
						mb={8}
					>
						{continueLabel}
					</Button>
					<FakeLink
						variant="primary"
						data-qa={remindMeLaterDataQa}
						sx={{ color: 'black' }}
						onClick={() => closeModal({ choice: false })}
						mt={16}
					>
						{remindMeLaterLabel}
					</FakeLink>
				</Flex>
			</Flex>
		);
	},
});
