import type { UtilityContext } from '@change/core/react/utilityContext';

import { getPetitionId } from 'src/app/shared/api/petitions';
import { FCM_SUPPORTER_VOICES_DETAILS_PAGE } from 'src/app/shared/fcm/configs';

import type { SupporterVoicesConfig } from './types';

export const getSupporterVoicesConfig = async (
	utilityContext: UtilityContext,
	slugOrId: string,
): Promise<SupporterVoicesConfig> => {
	let petitionId: string;
	if (Number(slugOrId)) petitionId = slugOrId;
	else petitionId = (await getPetitionId(slugOrId, utilityContext)) || '';

	const { supporterVoicesDetailsPageConfig } = await utilityContext.fcm.get({
		supporterVoicesDetailsPageConfig: FCM_SUPPORTER_VOICES_DETAILS_PAGE,
	});

	if (!supporterVoicesDetailsPageConfig)
		return {
			supporterVoicesEnabled: false,
		};

	const { enabled, blocklist, openVideoCreationFromPetitionDetails } = supporterVoicesDetailsPageConfig;

	const isInBlocklist = blocklist?.some((item) => item.id === petitionId);

	const supporterVoicesEnabled = Boolean(enabled) && !isInBlocklist;

	return {
		supporterVoicesEnabled,
		openVideoCreationFromPetitionDetails,
	};
};
