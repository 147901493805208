import { useUtilityContext } from '@change/core/react/utilityContext';

import { useTreatableExperimentVariation } from 'src/app/shared/hooks/experiment';
import { useIsMobile } from 'src/app/shared/hooks/userAgent';
import { isIPhoneOrIPad } from 'src/app/shared/utils/userAgent';

type Variant = Readonly<'control' | 'variant_1' | 'variant_2'>;

type Result =
	| {
			enabled: true;
			ratio: number;
	  }
	| {
			enabled: false;
	  };

export function useIOSAmounts(): () => Result {
	const getTreatedVariation = useTreatableExperimentVariation<Variant>('membership_ios_amounts_v2');
	const isMobile = useIsMobile();
	const utilityContext = useUtilityContext();

	return function iosAmounts() {
		if (!isMobile) return { enabled: false };
		if (!isIPhoneOrIPad(utilityContext)) return { enabled: false };
		const variant = getTreatedVariation();
		if (variant === 'variant_1') return { enabled: true, ratio: 1.2 };
		if (variant === 'variant_2') return { enabled: true, ratio: 1.4 };
		return { enabled: false };
	};
}
