import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_MEMBERSHIP_TRANSACTION_FEE_UPSELL = createFcmConfig('membership_transaction_fee_upsell', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
				variant_1: {
					optional: true,
					type: 'object',
					shape: {
						primary: { type: 'string' },
						secondary: { type: 'string' },
					},
				},
				variant_2: {
					optional: true,
					type: 'object',
					shape: {
						primary: { type: 'string' },
					},
				},
			},
		}),
	),
	defaultValue: undefined,
});
