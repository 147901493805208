import type { JSX } from 'react';

import { loadableReady } from '@loadable/component';
import qs from 'qs';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { CustomError } from 'ts-custom-error';

import { getDocument, getWindow } from '@change/core/window';

import type { BootstrapResults } from 'src/app/bootstrap';
import { bootstrap } from 'src/app/bootstrap';
import { markTimelineEnd, markTimelineStart } from 'src/app/shared/performance';

import type { HydrationData } from './types';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		__HYDRATION_DATA__?: HydrationData;
	}
}

type Options = Readonly<{
	createApp: (bootstrapData: BootstrapResults) => () => JSX.Element | null;
}>;

export class HydrationError extends CustomError {
	constructor(error: Error | string) {
		super(error instanceof Error ? `Hydration failure: ${error.message}` : `Hydration failure: ${error}`);
	}
}

// eslint-disable-next-line max-statements
export async function init({ createApp }: Options): Promise<void> {
	markTimelineEnd('pre-bootstrap');

	const window = getWindow();
	const document = getDocument();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const rootApp = document.getElementById('rootApp')!;

	const needHydration = !!window.__HYDRATION_DATA__;

	const skipHydration =
		process.env.NODE_ENV === 'development' &&
		qs.parse(window.location.search, { ignoreQueryPrefix: true }).hydrate === 'false';

	markTimelineStart('bootstrap');
	const bootstrapData = await bootstrap({ hydrationData: window.__HYDRATION_DATA__ });
	markTimelineEnd('bootstrap');

	const App = createApp(bootstrapData);

	const { errorReporter, environment } = bootstrapData.utilities;

	const sampleRate = environment.getEnvironment() === 'production' ? 0.01 : 1.0;
	const reportError = errorReporter.createSampledReporter(sampleRate);
	const reactErrorHandler = errorReporter.reactErrorHandler({ sampleRate });

	// if an error occured while bootstrapping, we are rendering an error page,
	// therefore we should not hydrate the page
	if (!bootstrapData.error && needHydration) {
		if (!skipHydration) {
			try {
				markTimelineStart('load lazy modules');
				await loadableReady();
				markTimelineEnd('load lazy modules');
				markTimelineStart('hydrate');
				hydrateRoot(rootApp, <App />, {
					onRecoverableError: reactErrorHandler,
				});
				markTimelineEnd('hydrate');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				reportError(new HydrationError(e));
				createRoot(rootApp, {
					onRecoverableError: reactErrorHandler,
				}).render(<App />);
			} finally {
				getDocument().documentElement.setAttribute('data-hydrated', 'true');
			}
		}
	} else {
		createRoot(rootApp, {
			onRecoverableError: reactErrorHandler,
		}).render(<App />);
		getDocument().documentElement.setAttribute('data-hydrated', 'true');
	}
}
