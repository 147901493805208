import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { useNavigate } from '@change/core/react/router';
import { useTracking as useTrackingBase } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCookiePrefAsync, useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useMembershipReminderCookie } from '../useMembershipReminderCookie';
import { useMembershipReminderModal } from '../useMembershipReminderModal';

type ModalData = Readonly<{
	heading: string;
	body: string;
	continueLabel: string;
	remindMeLaterLabel: string;
}>;

type Config =
	| {
			readonly experiment_name: string;
			readonly variant_1: ModalData;
			readonly variant_2: ModalData | undefined;
	  }
	| undefined;

const VISIBLE_LOCATIONS = [
	{ path: '/', name: 'home' },
	{ path: '/u/me', name: 'my_petitions' },
	{ path: /^\/browse($|\/)/, name: 'browse' },
	{ path: /^\/p($|\/)/, name: 'petition_detail' },
];

type MembershipReminderModal = {
	visited: number;
};

// eslint-disable-next-line max-lines-per-function
export function useAcceptMembershipReminderModal(): (variation: string, config: Config) => Promise<void> {
	const openReminderModal = useMembershipReminderModal();
	const track = useTrackingBase();
	const navigate = useNavigate();
	const session = useSessionAsync();
	const { pathname } = useLocation();
	const {
		actions: { setMembershipReminderCookie },
	} = useMembershipReminderCookie();
	const { cookies } = useUtilityContext();
	const cookieAllowedState = useCookiePrefAsync('preferences');

	const visiblePage = useMemo(() => {
		return VISIBLE_LOCATIONS.find((visibleLocation) =>
			typeof visibleLocation.path === 'string'
				? visibleLocation.path === pathname
				: visibleLocation.path.test(pathname),
		);
	}, [pathname]);

	// eslint-disable-next-line complexity
	return async function acceptMembershipReminderModal(variation: string, config: Config): Promise<void> {
		if (!isLoaded(session) || session?.value.hasMembership || session.value.loginState === 'GUEST') return; // do not show modal for guests or members

		if (variation === 'control' || !config) return; // do not show modal for control group

		const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;
		if (!cookieAllowed) return; // do not show modal if cookies are not allowed

		const membershipReminderCookie = cookies.get('app_meta_membership_reminder') ?? undefined;
		if (!membershipReminderCookie) return; // do not show modal if cookie is not set

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const reminderCookie = (JSON.parse(membershipReminderCookie) as MembershipReminderModal) || undefined;

		if (!reminderCookie || reminderCookie.visited > 1) {
			return; // do not show modal if cookie is not set or visited more than once
		}

		const modalData = variation === 'variant_1' ? config.variant_1 : config.variant_2;
		if (!modalData) return; // do not show modal if variant is not found

		if (
			!(await openReminderModal(
				{
					...modalData,
					text: modalData.body,
					heading: modalData.heading.replace(
						'{name}, ',
						session.value.user && session.value.user.firstName ? `${session.value.user.firstName}, ` : '',
					),
				},
				visiblePage?.name ?? pathname,
			))
		) {
			void track('membership_reminder_click', { action: 'remind me later' });
			void setMembershipReminderCookie({ visited: 2 });
		} else {
			void track('membership_reminder_click', { action: 'continue to membership' });
			void setMembershipReminderCookie({ visited: 2 });
			navigate(`/member/join?source_location=membership_reminder_${visiblePage?.name}`);
		}
	};
}
