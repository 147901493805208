import type { SessionUserRole } from '@change/core/session';

import type { LoginState } from '@change-corgi/middle-end/gql';

import type {
	AppRoute,
	AppRouteRestrictedAccessCheckContext,
	RestrictedAccessExpectedBehavior,
} from 'src/shared/routes';

import { isArray } from 'src/app/shared/utils/arrays';

type Params = {
	loginState: LoginState;
	roles?: Record<SessionUserRole, boolean>;
};

async function checkRestrictedAccessAdditionalCheck(
	route: Pick<AppRoute, 'restrictedAccess'>,
	context: AppRouteRestrictedAccessCheckContext,
): Promise<RestrictedAccessExpectedBehavior | undefined> {
	if (!isRestrictedAccessCheckNecessary(route)) {
		return undefined;
	}

	const { restrictedAccess } = route;

	if (!restrictedAccess.additionalCheck) {
		return undefined;
	}

	const res = await restrictedAccess.additionalCheck(context);

	// redirecting to the login page doesn't work (infinite redirects)
	// as the user is already auth, so we're using the home page instead
	// TODO: render a "forbidden" page once it's available (https://github.com/change/corgi/pull/8591)
	return res ? undefined : 'forbidden';
}

export async function checkRestrictedAccess(
	route: Pick<AppRoute, 'restrictedAccess'>,
	params: Params,
	context: AppRouteRestrictedAccessCheckContext,
): Promise<RestrictedAccessExpectedBehavior | undefined> {
	return (
		(await checkRestrictedAccessBase(route, params, context)) || checkRestrictedAccessAdditionalCheck(route, context)
	);
}

async function checkRestrictedAccessBase(
	route: Pick<AppRoute, 'restrictedAccess'>,
	{ loginState, roles }: Params,
	context: AppRouteRestrictedAccessCheckContext,
): Promise<RestrictedAccessExpectedBehavior | undefined> {
	if (!isRestrictedAccessCheckNecessary(route)) {
		return undefined;
	}

	const { restrictedAccess } = route;

	if (restrictedAccess.authOnly && loginState !== 'AUTHENTICATED') {
		return 'login_page';
	}
	if (restrictedAccess.nonGuestOnly && loginState === 'GUEST') {
		return 'login_page';
	}

	// necessary due to a bug in webpack TS parsing (TS_NODE_PROJECT) where restrictedAccess' type is somehow inferred to "never"
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/consistent-type-assertions
	const roleConfig = (restrictedAccess as NonNullable<AppRoute['restrictedAccess']>).role;

	if (!roleConfig) {
		return undefined;
	}

	const allowedRoles = isArray(roleConfig) ? roleConfig : [roleConfig];
	const checkResult = await allowedRoles.reduce(async (promise, role) => {
		const res = await promise;
		if (res) return res;
		if (typeof role === 'string') return roles ? roles[role] : false;
		return role(context);
	}, Promise.resolve(false));
	if (!checkResult) {
		// redirecting to the login page doesn't work (infinite redirects)
		// as the user is already auth, so we're using the admin page instead
		// TODO: render a "forbidden" page once it's available (https://github.com/change/corgi/pull/8591)
		return 'forbidden';
	}

	return undefined;
}

export function isRestrictedAccessCheckNecessary(
	route: Pick<AppRoute, 'restrictedAccess'>,
): route is { restrictedAccess: NonNullable<AppRoute['restrictedAccess']> } {
	if (!route?.restrictedAccess?.authOnly && !route?.restrictedAccess?.nonGuestOnly) {
		return false;
	}
	return true;
}
