import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		supportedLocales: {
			type: 'array',
			shape: { type: 'string' },
		},
	},
});

export const FCM_COMMS_STACK_DM_ADDED_ENGAGEMENT_VALUE_PROPS_ENABLED = createFcmConfig(
	'comms_stack_dm_added_engagement_value_props_enabled',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: {
			enabled: false,
			supportedLocales: [],
		},
	},
);
