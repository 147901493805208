import type { UtilityContext } from '@change/core/react/utilityContext';

import { RedirectError } from 'src/shared/error';

import type { SupporterVoicesConfig } from 'src/app/pages/petitionGamma/details/shared/api';
import { getSupporterVoices, getSupporterVoicesConfig } from 'src/app/pages/petitionGamma/details/shared/api';
import { differenceInDays } from 'src/app/shared/utils/dates';

import type { GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables } from './api.graphql';
import { GammaPetitionDetailsData } from './api.graphql';
import { getContactDmConfig } from './getContactDmConfig';
import { getPetitionStrengthScore } from './getPetitionStrengthScore';
import type { PetitionDetailsPageData } from './types';

export async function getPetitionDetailsPageData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{
		locale,
		countryCode,
		loadBandits,
		supporterVoicesConfig,
	}: {
		locale: string;
		countryCode: string;
		loadBandits: boolean;
		supporterVoicesConfig?: SupporterVoicesConfig;
	},
): Promise<PetitionDetailsPageData> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { dmContactsToFetch, emailBody, emailSubjectLine } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables>({
		query: GammaPetitionDetailsData,
		variables: {
			slugOrId,
			locale,
			countryCode,
			loadBandits,
			dmContactsToFetch,
		},
		rejectOnError: true,
		// to separate query from other batched queries
		important: true,
	});

	if (!data?.petition) {
		throw new RedirectError({ url: '/?petition_not_found=true' });
	}

	const config = supporterVoicesConfig || (await getSupporterVoicesConfig(utilityContext, slugOrId));

	const supporterVoicesData = await getSupporterVoices(
		utilityContext,
		slugOrId,
		config,
		data?.petition?.videoSettingsMetadata.disableDisplay,
	);

	return {
		petition: data.petition,
		recommendedDecisionMakers: data.recommendedDecisionMakers?.items || [],
		createdLessThanOneDayAgo: differenceInDays(new Date(), new Date(data.petition.createdAt)) < 1,
		score: getPetitionStrengthScore(data.petition),
		supporterVoicesData,
		emailDm: { emailSubjectLine, emailBody },
	};
}
