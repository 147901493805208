import { perimeterxConfigs } from '@change/config/perimeterx';
import type { EnvironmentUtils } from '@change/core/environment';
import { createErrorReporter as _createErrorReporter } from '@change/core/errorReporter/client';
import type { ErrorReporter } from '@change/core/errorReporter/common';
import { getLocation } from '@change/core/window';

import airbrakeConfig from 'config/airbrake.browser.json';
import sentryConfig from 'config/sentry.browser.json';

import webappInfo from 'src/webappInfo';

import {
	filterOutBrowserExtensions,
	filterOutCurrentUrls,
	filterOutEmptyFileNames,
	filterOutHttpAndRedirectErrors,
	filterOutMiscErrors,
	filterOutQaaScripts,
} from './filters';
import {
	additionalContext,
	additionalParams,
	fixFacebookSdkBackTraceItems,
	fixPerimeterxBackTraceItems,
	fixUrlBackTraceItems,
} from './processors';

type Options = Readonly<{
	environment: EnvironmentUtils;
}>;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		newrelic: Readonly<{
			noticeError: (error: string | Error, customAttributes?: Record<string, unknown>) => void;
			// ... and a bunch of other stuff
		}>;
	}
}

const BREADCRUMBS_IGNORED_DOMAINS = [
	// NewRelic
	'bam.nr-data.net',
	'bam-cell.nr-data.net',
	// Airbrake
	'api.airbrake.io',
	'notifier-configs.airbrake.io',
	// Sentry
	/^.*\.sentry\.io$/,
	// PerimeterX
	/^.*\.px-cdn\.net$/,
	/^.*\.px-cloud\.net$/,
	/^.*\.px-client\.net$/,
	// Optimizely
	/^.*\.optimizely\.com$/,
	// Google Analytics
	'www.google-analytics.com',
];

export function createErrorReporter({ environment }: Options): ErrorReporter {
	const { hostname } = getLocation();

	const pxId = perimeterxConfigs[environment.getEnvironment()].appId;

	return (
		_createErrorReporter({
			environment: environment.getEnvironment(),
			subEnvironment: environment.getDemoEnvironment() || environment.getEnvironment(),
			hostname,
			webappInfo,
			reporters: {
				airbrake: airbrakeConfig,
				sentry: {
					// production DSN has a rate limit configured at the project level
					dsn: environment.getEnvironment() === 'production' ? sentryConfig.dsn.production : sentryConfig.dsn.other,
					applicationKey: sentryConfig.applicationKey,
					// sampleRate: environment.getEnvironment() === 'production' ? 0.5 : 1.0
					htmlPiiSelectors: {
						mask: ['[data-pii-unsafe]'],
						unmask: ['[data-pii-safe]'],
					},
					breadcrumbs: {
						domSerializedAttributes: ['data-testid', 'data-qa'],
						ignoredDomains: BREADCRUMBS_IGNORED_DOMAINS,
					},
				},
			},
			additionalContext,
			additionalParams,
			silencedBots: true,
			...(process.env.ERROR_REPORTING_ENABLED ? { silencedEnvironments: [] } : {}),
		})
			// these filters ans processors are only used by Airbrake
			.addFilter(filterOutHttpAndRedirectErrors)
			.addFilter(filterOutBrowserExtensions)
			.addFilter(filterOutQaaScripts)
			.addFilter(filterOutEmptyFileNames)
			.addFilter(filterOutCurrentUrls)
			.addFilter(filterOutMiscErrors)
			.addProcessor(fixFacebookSdkBackTraceItems)
			.addProcessor(fixPerimeterxBackTraceItems(pxId))
			.addProcessor(fixUrlBackTraceItems)
			.toggleLogging(environment.getEnvironment() === 'development')
			.init()
	);
}
